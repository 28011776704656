<template>
    <div>
      <article>
        <section>
          <div class="country_dialogs">
            <el-dialog
              :title="`Reservation Details`"
              :visible.sync="isReservationDetailsDialogVisible"
              :destroy-on-close="true"
              :before-close="handleClose"
              style="text-align: left"
            >
              <div v-loading="submitting">
                <div>
                    <h4>Customer Info:</h4>
                    <div class="row">
                      <div class="col-md-6">
                        <span>Room: <strong>{{ reservationData.categoryName }}</strong></span><br/>
                        <span>Name: <strong>{{ reservationData.fullName }}</strong></span><br/>
                        <span>Email: <strong>{{ reservationData.email }}</strong></span><br/>
                        <span>Phone No: <strong>{{ reservationData.phoneNumber }}</strong></span><br/>
                      </div>
                      <div class="col-md-6">
                        <span>Address: <strong>{{ reservationData.address }}</strong></span><br/>
                        <span>Country: <strong>{{ reservationData.country }}</strong></span><br/>
                        <span>No. of Adults: <strong>{{ reservationData.numberOfAdults }}</strong></span><br/>
                        <span>No. of Children: <strong>{{ reservationData.numberOfChildren }}</strong></span><br/>
                      </div>
                    </div>
                    <hr>
                </div>
                <div>
                    <h4>Reservation Info:</h4>
                    <div>
                        <span>Total Amount: <strong> ${{ formatNumber(reservationData.totalAmount) }} </strong></span><br />
                        <span>Check In Date: <strong>{{ formattedDate(reservationData.checkInDate) }}</strong></span><br/>
                        <span>Check Out Date: <strong>{{ formattedDate(reservationData.checkOutDate) }}</strong></span><br/>
                        <span>Time of Reservation: <strong>{{ formattedDate(reservationData.reservationDate) }}</strong></span>
                    </div>
                    <hr>
                </div>
                <div>
                    <h4>Reservation Status:</h4>
                    <div>
                        <p
                            v-if="reservationData.reservationStatus == 'CONFIRMED'"
                            style="color: green; font-size: 12px; font-weight: 500;"
                        > <i class="el-icon-check"></i> Confirmed</p>
                        <p
                            v-if="reservationData.reservationStatus == 'REJECTED'"
                            style="color: red; font-size: 12px; font-weight: 500;"
                        > <i class="el-icon-close"></i> Rejected</p>
                        <p
                            v-if="reservationData.reservationStatus == 'PENDING'"
                            style="color: orange; font-size: 12px; font-weight: 500;"
                        > <i class="el-icon-alarm-clock"></i> Pending</p>
                    </div>
                    <hr>
                </div>

                <div>
                  <h4>My Special Requests</h4>
                  <p>{{ reservationData.specialRequests }}</p>
                </div>
                <div class="text-center">
                  <div class="text-center">
                    <small>© 2024 Acacia Wilderness Mweya, All rights reserved.</small>
                  </div>
                  <div class="text-center">
                    <small>Design by <a href="https://ovalspace.co" style="color: black;"><strong>Ovalspace</strong></a></small>
                  </div>
                </div>
              </div>
            </el-dialog>
          </div>
        </section>
      </article>
    </div>
  </template>
  
  <script>
    import { format, parseISO } from "date-fns";
    export default {
        data() {
        return {
            loading: false,
            isLoading: false,
            isLoadingError: false,
    
            isReservationDetailsDialogVisible: false,
            loadingError: false,
            submitting: false,
        };
        },
    
        props: {
        showReservationDetailsDialog: {
            required: true,
            type: Boolean,
        },
        reservationData: {
            required: true,
            type: Object,
        },
        },

        computed: {
        formattedDate() {
            return (dateString) => format(parseISO(dateString), "do MMM yyyy hh:mma");
        },
        },
    
        watch: {
        showReservationDetailsDialog() {
            if (this.showReservationDetailsDialog === true) {
            this.isReservationDetailsDialogVisible = true;
            this.getCategoryRooms();
            } else {
            this.isReservationDetailsDialogVisible = false;
            }
        },
        },
    
        methods: {
            handleClose(done) {
                done();
                this.roomForm.roomID = "";
                this.$emit("closeReservationDetailsDialog");
            },
                
            formatNumber(num) {
            return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
            },
        },
    };
  </script>
  
  <style>
  .el-select {
    width: 100%;
  }
  </style>