<template>
  <div v-loading="loading">
    <section>
      <article class="mt-5">
        <div class="container mt-5">

          <div class="row w-100 mb-4">
            <div class="col-12 pl-3 px-0 mb-0 text-left">
              <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item :to="{ path: '/smartlodge/admin/' }">Home</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path: '/smartlodge/admin/reservations' }">Reservations</el-breadcrumb-item>
              </el-breadcrumb>
            </div>
          </div>

          <h3><b>Reservations:</b></h3>
          <div
            style="display: flex; align-items:center; justify-content: space-between;"
            class="search_add_section"
          >
            <div style="width: 50%;">
              <input
                type="text"
                v-model.trim="filters[0].value"
                placeholder="Search by Guest Name"
                class="search_by_input form-control"
                spellcheck="off"
              />
            </div>
            <div style="margin-left: 20px">
              <el-select
                v-model="selectedFilter"
                placeholder="Select"
                class="d-block"
                style="height: 40px !important"
              >
                <el-option
                  v-for="item in filterOptions"
                  :key="item"
                  :label="item"
                  :value="item"
                >
                </el-option>
              </el-select>
            </div>
          </div>
          <hr />
          <div
            v-loading="loading"
            class="table-responsive mb-5"
          >
            <!-- {{ formattedReservations }} -->
            <data-tables
              :data="formattedReservations"
              :table-props="tableProps"
              :pagination-props="{ pageSizes: [5, 20, 25, 50, 100] }"
              type="expand"
              :filters="filters"
            >

              <div slot="empty">
                <ScaleOut
                  v-if="isLoading && !loadingError"
                  class="mt-3"
                  :background="'#164B70'"
                />
                <div
                  @click="getReservations"
                  v-else-if="!isLoading && loadingError"
                  style="cursor: pointer"
                >
                  Unable to Load reservations Now. Please click here to retry
                </div>
                <div v-if="!isLoading && !loadingError">No reservations</div>
              </div>

              <el-table-column
                label="#"
                :show-overflow-tooltip="false"
                label-class-name="text-center"
                class-name="text-center"
                width="80px"
              >
                <template slot-scope="scope">
                  <span>{{ scope.$index + 1 }}</span>
                </template>
              </el-table-column>
              
              <el-table-column
                label="customer"
                :show-overflow-tooltip="false"
                label-class-name="text-left"
                width="auto"
              >
                <template slot-scope="scope">
                  <span>Room Category: <strong>{{ scope.row.categoryName }}</strong></span><br/>
                  <span>Name: <strong>{{ scope.row.fullName }}</strong></span><br/>
                  <span>No of Adults: <strong>{{ scope.row.numberOfAdults }}</strong></span><br/>
                  <span>No of Children: <strong>{{ scope.row.numberOfChildren }}</strong></span>
                </template>
              </el-table-column>

              <el-table-column
                label="Reservation Info"
                :show-overflow-tooltip="false"
                label-class-name="text-left"
                class-name="text-left"
              >
                <template slot-scope="scope">
                  <span>Total Amount: <strong> ${{ formatNumber(scope.row.totalAmount) }} </strong></span><br />
                  <span>Time of Reservation: <strong>{{ formattedDate(scope.row.reservationDate) }}</strong></span>
                </template>
              </el-table-column>

                <el-table-column
                  label="Approval"
                  :show-overflow-tooltip="true"
                  label-class-name="text-center"
                  class-name="text-center"
                  width="auto"
                >
                  <template slot-scope="scope">
                    <span
                      v-if="scope.row.reservationStatus == 'CONFIRMED'"
                      style="color: green; font-size: 12px; font-weight: 500;"
                    > <i class="el-icon-check"></i> Confirmed</span>
                    <span
                      v-if="scope.row.reservationStatus == 'REJECTED'"
                      style="color: red; font-size: 12px; font-weight: 500;"
                    > <i class="el-icon-close"></i> Rejected</span>
                    <span
                      v-if="scope.row.reservationStatus == 'PENDING'"
                      style="color: orange; font-size: 12px; font-weight: 500;"
                    > <i class="el-icon-alarm-clock"></i> Pending</span>

                  </template>
                </el-table-column>

              <el-table-column
                label="Rooms"
                :show-overflow-tooltip="false"
                label-class-name="text-center"
                class-name="text-center"
              >
                <template slot-scope="scope">
                  <el-button
                    type="info"
                    :disabled="false"
                    @click="showAddToBookingsData(scope.row)"
                    round
                  >Add To Bookings</el-button>
                </template>
              </el-table-column>

              <el-table-column
                label="Actions"
                :show-overflow-tooltip="false"
                label-class-name="text-center"
                class-name="text-center"
              >
                <template slot-scope="scope">
                  <el-row style="display: flex; align-items:center; justify-content:center;">
                    
                    <el-tooltip
                            content="Details"
                            placement="top"
                        >
                    <el-button
                      @click="showReservationDetailsData(scope.row)"
                      type="primary"
                      :disabled="false"
                      icon="el-icon-view"
                      circle
                    ></el-button>
                    </el-tooltip>
                        <el-tooltip
                          content="Delete"
                          placement="top"
                        >
                            <el-button
                              type="danger"
                              :disabled="$store.state.userRoles !== 'SUPER_ADMIN'"
                              @click="deleteReservation(scope.row.reservationID)"
                              icon="el-icon-delete"
                              circle
                            ></el-button>
                        </el-tooltip>
                  </el-row>
                </template>
              </el-table-column>

            </data-tables>
          </div>

        </div>
      </article>
    </section>

    <OrderDetailsDialog
      :orderDetailsDialogVisible="orderDetailsDialogVisible"
      :orderData="order_data"
      @closeOrderDetailsDialog="closeOrderDetailsDialog"
    />

    <AddToBookingsDialog
      :showAddToBookingsDialog="showAddToBookingsDialog"
      :reservationData="reservationData"
      @closeAddToBookingsDialog="closeAddToBookingsDialog"
      @re-fresh="getReservations"
    />

    <ReservationDetailsDialog
      :showReservationDetailsDialog="showReservationDetailsDialog"
      :reservationData="reservationData"
      @closeReservationDetailsDialog="closeReservationDetailsDialog"
      @re-fresh="getReservations"
    />

  </div>
</template>

<script>
import ScaleOut from "@/components/backoffice/scale-out-component.vue";
import OrderDetailsDialog from "../../components/backoffice/dialogs/order-details-dialog.vue";
import AddToBookingsDialog from "../../components/backoffice/dialogs/add-to-bookings-dialog";
import ReservationDetailsDialog from "../../components/backoffice/dialogs/reservation-details.vue";
import { format, parseISO } from "date-fns";
export default {
  components: {
    ScaleOut,
    OrderDetailsDialog,
    AddToBookingsDialog,
    ReservationDetailsDialog
  },

  data() {
    return {
      loading: false,
      isLoading: false,
      orderDetailsDialogVisible: false,
      reservations: [],
      order_data: {},
      showAddToBookingsDialog: false,
      showReservationDetailsDialog: false,
      reservationData: {},

      tableData: [],
      tableProps: {
        border: true,
        stripe: true,
      },

      filters: [
        {
          prop: "fullName",
          value: "",
        },
      ],
      loadingError: false,

      selectedFilter: "",
      filterOptions: [
        "Show All",
        "Show Confirmed",
        "Show Pending"
      ],
    };
  },

  computed: {
    formattedDate() {
      return (dateString) => format(parseISO(dateString), "do MMM yyyy hh:mma");
    },

    formattedReservations() {
      if (this.selectedFilter === "Show All") {
        return this.reservations;
      } else if (this.selectedFilter === "Show Confirmed") {
        return this.reservations.filter((item) => {
          return item.reservationStatus === "CONFIRMED";
        });
      } else if (this.selectedFilter === "Show Pending") {
        return this.reservations.filter((item) => {
          return item.reservationStatus === "PENDING";
        });
      }
      else {
        return this.reservations.filter((item) => {
          return item.reservationStatus === "PENDING";
        });
      }
    },
  },

  mounted() {
    this.getReservations();
  },

  methods: {
    formatNumber(num) {
      return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    },
      
      showAddToBookingsData(reservationData) {
        this.showAddToBookingsDialog = true;
        this.reservationData = reservationData;
      },

      closeAddToBookingsDialog() {
        this.showAddToBookingsDialog = false;
      },
      
      showReservationDetailsData(reservationData) {
        this.showReservationDetailsDialog = true;
        this.reservationData = reservationData;
      },

      closeReservationDetailsDialog() {
        this.showReservationDetailsDialog = false;
      },

    showOrderDetailsDialog(orderData) {
      this.orderDetailsDialogVisible = true;
      this.order_data = orderData;
    },

    closeOrderDetailsDialog() {
      this.orderDetailsDialogVisible = false;
    },

    async getReservations() {
      this.isLoading = true;
      this.isLoadingError = false;
      try {
        const token = JSON.parse(localStorage.getItem("user"));
        let request = await this.$http.get(`reservations`);
        if (
          request.data.success &&
          request.data.message === "RESERVATIONS_FETCHED_SUCCESSFULLY"
        ) {
          this.reservations = request.data.reservations.map(reservation => ({
            fullName: `${reservation.firstName} ${reservation.lastName}`,
            reservationID: reservation.reservationID,
            customerID: reservation.customerID,
            roomCategoryID: reservation.roomCategoryID,
            checkInDate: reservation.checkInDate,
            checkOutDate: reservation.checkOutDate,
            reservationDate: reservation.reservationDate,
            reservationStatus: reservation.reservationStatus,
            totalAmount: reservation.totalAmount,
            numberOfAdults: reservation.numberOfAdults,
            numberOfChildren: reservation.numberOfChildren,
            specialRequests: reservation.specialRequests,
            email: reservation.email,
            phoneNumber: reservation.phoneNumber,
            address: reservation.address,
            country: reservation.country,
            createdAt: reservation.createdAt,
            categoryName: reservation.categoryName,
            categoryID: reservation.categoryID
          }));
        } else throw "UNEXPECTED_RESPONSE_RECEIVED";
      } catch (error) {
        this.isLoadingError = true;
        //TODO: REPORT THIS TO AN ERROR REPORTING SERVICE
        this.$notify({
          title: "Operation Failed",
          message: "Unable to fetch Orders now, please try again",
          type: "error",
        });
      } finally {
        this.isLoading = false;
      }
    },

    async deleteReservation(reservationID) {
        this.$confirm(
          // eslint-disable-line no-unreachable
          "This will permanently delete this Reservation. Do you want to Continue?",
          "Confirm delete",
          {
            confirmButtonText: "Delete",
            cancelButtonText: "Cancel",
            type: "warning",
          }
        )
          .then(async () => {
            try {
              this.deleting = true;
              let request = await this.$http.delete(`reservations/${reservationID}`);
              if (
                request.data.success &&
                request.data.message === "RESERVATION_DELETED_SUCCESSFULLY"
              ) {
                this.getReservations();
                this.$notify({
                  title: "Success",
                  message: "Reservation deleted successfully!",
                  type: "success",
                });
              } else {
                throw "UNEXPECTED_RESPONSE";
              }
            } catch (error) {
              if (error.message == "Network Error") {
                this.loadingError = true;
                return this.$notify({
                  title: "Connection failed",
                  message: "A network error occurred, please try again.",
                  type: "error",
                });
              }
              this.$notify({
                title: "Unable to Delete",
                message: "Unable to delete the Reservation, Please try again",
                type: "error",
              });
            } finally {
              this.deleting = false;
            }
          })
          .catch(() => {
            //
          });
    },
  },
};
</script>

<style>
.el-form-item {
  margin-bottom: 0px;
}

.el-form--label-top .el-form-item__label {
  float: none;
  display: inline-block;
  text-align: left;
  padding: 0 0 0px;
}
label {
  display: inline-block;
  margin-bottom: 0px;
}
</style>