<template>
  <div>
    <article>
      <section>
        <div class="size_dialogs">
          <el-dialog
            :title="`Order Details`"
            :visible.sync="isOrderDetailsDialogVisible"
            :destroy-on-close="true"
            :before-close="handleClose"
            style="text-align: left"
          >
            <div class="row">
              <div class="col-md-12">
                <hr />
                <h2 style="font-size: 1.5em; font-weight: 800; color:black;">Products</h2>
                <!-- {{ productsDetails }} -->

                <section>
                  <article class="pt-5 pb-5">
                    <div class="container ">

                      <template>
                        <el-table
                          :data="productsDetails"
                          border
                          style="width: 100%"
                        >
                          <el-table-column
                            label="Item Image"
                            width="120"
                            label-class-name="text-center"
                            class-name="text-center"
                          >
                            <template slot-scope="scope">
                              <div v-if="scope.row.color !== null">
                                <img
                                  :src="scope.row.color.cover_image"
                                  style="width: 80px; height: 80px; object-fit: cover"
                                />
                              </div>
                              <div v-else>
                                <img
                                  :src="scope.row.main_image"
                                  style="width: 80px; height: 80px; object-fit: cover"
                                />
                              </div>
                            </template>
                          </el-table-column>
                          <el-table-column
                            label="Item Info"
                            width="auto"
                          >
                            <template slot-scope="scope">
                              <p>
                                <small><strong>{{ scope.row.product_name }}</strong></small>
                              </p>
                              <p v-if="scope.row.color !== null">
                                <span><small>Color: {{ scope.row.color.color_name }}</small></span>
                              </p>
                              <p v-if="scope.row.size !== null">
                                <span><small>Size: {{ scope.row.size }}</small></span><br />
                              </p>
                            </template>
                          </el-table-column>
                          <el-table-column label="Quantity & Pricing">
                            <template slot-scope="scope">
                              <p>
                                <span>
                                  <small>Cost: {{ orderData.currency }} {{ formatNumber(scope.row.product_price) }} </small>
                                </span>
                                <span :id="scope.row.product_id">
                                  <small>
                                    <strong> Total: </strong>
                                    {{ orderData.currency }}
                                    {{ formatNumber(scope.row.product_price * scope.row.quantity) }}
                                  </small>
                                </span>
                              </p>
                            </template>
                          </el-table-column>
                        </el-table>
                      </template>
                    </div>
                  </article>
                </section>
              </div>
            </div>

            <div class="row">
              <div class="col-md-12">
                <hr />
                <h2 style="font-size: 1.5em; font-weight: 800; color:black;">Shipping Details</h2>
                <p>Name: {{ shippingDetails.fname }}</p>
                <p>Email: {{ shippingDetails.email }}</p>
                <p>Phone: {{ shippingDetails.phone }}</p>
                <p>Address:{{ shippingDetails.address }}</p>
                <p>Country:{{ shippingDetails.country }}</p>
                <hr />
              </div>
            </div>
          </el-dialog>
        </div>
      </section>
    </article>
  </div>
</template>

<script>
import { format, parseISO } from "date-fns";
export default {
  data() {
    return {
      loading: false,
      isOrderDetailsDialogVisible: false,
      submitting: false,
    };
  },
  props: {
    orderDetailsDialogVisible: {
      required: true,
      type: Boolean,
    },
    orderData: {
      required: true,
      type: Object,
    },
  },

  watch: {
    orderDetailsDialogVisible() {
      if (this.orderDetailsDialogVisible === true) {
        this.isOrderDetailsDialogVisible = true;
      } else {
        this.isOrderDetailsDialogVisible = false;
      }
    },
  },

  computed: {
    formattedDate() {
      return (dateString) => format(parseISO(dateString), "do MMM yyyy hh:mma");
    },
    shippingDetails() {
      return JSON.parse(this.orderData.shipping_details);
    },
    productsDetails() {
      return [JSON.parse(this.orderData.products)].flat();
    },
  },
  methods: {
    handleClose(done) {
      done();
      this.$emit("closeOrderDetailsDialog");
    },

    formatNumber(num) {
      return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    },
  },
};
</script>
<style>
.size_dialogs .el-dialog {
  width: 80%;
}
/* ##Device = Tablets, Ipads (portrait) ##Screen = B/w 768px to 1024px */
@media (min-width: 768px) and (max-width: 1024px) {
  .size_dialogs .el-dialog {
    width: 90%;
  }
}
/* ##Device = Tablets, Ipads (landscape) ##Screen = B/w 768px to 1024px */
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .size_dialogs .el-dialog {
    width: 90%;
  }
}
/* ##Device = Low Resolution Tablets, Mobiles (Landscape) ##Screen = B/w 481px to 767px */
@media (min-width: 481px) and (max-width: 767px) {
  .size_dialogs .el-dialog {
    width: 90%;
  }
}
/* ##Device = Most of the Smartphones Mobiles (Portrait) ##Screen = B/w 320px to 479px */
@media (min-width: 320px) and (max-width: 480px) {
  .size_dialogs .el-dialog {
    width: 90%;
  }
}
</style>